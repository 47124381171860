<template>
  <div id="app">
    <section class="section position-relative">
      <div class="mt-4 flex-wrap justify-content-between">
        <div class="p-0 col-sm-auto mb-2">
          <div class="p-0 col-sm-auto m-0">
            <div class="d-flex my-auto">
              <div class="col-md-auto my-auto p-0 mr-2">
                <h4 class="card-title my-auto">Liste des tâches</h4>
              </div>
              <DropDownCustom
                class="my-auto"
                :items="['Tableau', 'Kanban', 'Gantt']"
                @changeValue="onValueChange"
              />
              <div class="my-auto" v-if="selected == 2">
                <b-dropdown
                  id="dropdown-1"
                  :text="selectedView.text"
                  :class="`custom-drop m-md-2 btn-outline-info ${selectedView.color} `"
                >
                  <b-dropdown-item
                    class="col-sm-6 col-md-auto m-0 p-0"
                    v-for="(view, index) in ganttView"
                    :key="index"
                  >
                    <button
                      @click="changeView(index)"
                      type="button"
                      :class="`btn ${view.color} btn-fw col-sm-12`"
                    >
                      {{ view.text }}
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <div
              class="
                row
                col-md-auto
                p-0
                mr-0
                col-12
                py-1
                align-items-end
                ml-auto
                my-auto
              "
            >
              <div
                class="
                  pl-0
                  pr-0
                  col-md-auto
                  my-auto
                  col-6
                  mb-2
                  ml-auto
                  col-md-2
                "
                style="width: 20%"
              >
                <v-select
                  type="text"
                  v-model="searchQuery.status"
                  placeholder="Statut "
                  :options="STATUS_TASKS"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <div
                class="pl-1 pr-0 col-md-auto my-auto col-6 mb-2 col-md-2"
                style="width: 20%"
              >
                <v-select
                  type="text"
                  v-model="searchQuery.echeance"
                  placeholder="Statut échéance "
                  :options="['Nouveau', 'En retard', 'En cours']"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <div
                class="pl-1 pr-0 col-md-auto my-auto col-6 mb-2 col-md-2"
                style="width: 20%"
              >
                <b-form-input
                  type="text"
                  v-model="searchQuery.libelle"
                  placeholder="Libellé "
                ></b-form-input>
              </div>
              <div
                class="pl-1 pr-0 col-md-auto my-auto col-6 mb-2 col-md-2"
                style="width: 20%"
              >
                <b-form-input
                  type="text"
                  v-model="searchQuery.initiative"
                  placeholder="Initiative "
                ></b-form-input>
              </div>

              <div
                class="pl-1 pr-0 col-md-auto col-6 add mt-sm-0 col-md-2"
                style="width: 20%"
                v-if="
                  isManager ||
                  isCollaborator ||
                  isArchitect ||
                  isPo ||
                  isFinance ||
                  isAdmin ||
                  isSuper
                "
              >
                <button
                  type="button"
                  class="
                    btn btn-block
                    px-2
                    test-btn
                    text-white
                    align-items-center
                  "
                  :style="{
                    height: '44.22px',
                    backgroundColor: !filterMyTask ? '#FFC000' : '#008000',
                  }"
                  @click="myTasks"
                >
                  <i class="mdi mdi-format-list-checks mr-2"></i>
                  Mes tâches
                </button>
              </div>

              <div
                class="mt-sm-0 pl-1 pr-0 col-md-auto col-6 col-md-2"
                style="width: 20%"
                v-if="isManager || isAdmin || isSuper"
              >
                <button
                  type="button"
                  class="
                    btn btn-block
                    test-btn
                    px-2
                    text-center text-white
                    align-content-center align-items-center
                  "
                  :style="{
                    height: '44.22px',
                    backgroundColor: !filterMyTeamTask ? '#FFC000' : '#008000',
                  }"
                  @click="myTeamTasks"
                >
                  <i class="mdi mdi-account-group mr-2"></i>
                  Mon équipe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0 bg-secondary" />

      <div style="position: relative" v-if="selected == 0">
        <TaskTable
          class="mt-3"
          :items="filteredTasks"
          @editItem="$router.push({ name: 'edit-task' })"
          @deleteItem="deleteItem"
          :loading="loadingData"
          :totalItems="ALL_TASKS.length"
        />
      </div>
      <div v-if="selected == 1" class="board-wrapper">
        <Kanban @dragged="onDragged" :stages="STATUS_TASKS" :blocks="filteredTasks">
          <div v-for="stage in STATUS_TASKS" :slot="stage" :key="stage" class="">
            <h2>
              {{ stage }}
            </h2>
          </div>
          <div v-for="item in filteredTasks" :slot="item.id" :key="item.id">
            <div>
              <b-progress :value="item.avancement || 0">
                <b-progress-bar
                  :style="`background-color:${
                    item.status === 'En cours'
                      ? '#4287f5'
                      : item.status === 'En attente'
                      ? '#a35837'
                      : item.status === 'Suspendu'
                      ? 'red'
                      : item.status === 'Terminé'
                      ? '#0f7316'
                      : 'gray'
                  };`"
                  :value="item.avancement || 0"
                ></b-progress-bar>
              </b-progress>
              <div class="d-flex justify-content-between">
                <p
                  @click="
                    $router.push({
                      name: 'show-task',
                      params: { slug: item.slug || 'abcde', id: item.id },
                    })
                  "
                  class="task-date"
                >
                  {{ moment(formatDate(item.createdAt)).format("DD/MM/YYYY") }}
                </p>
                <div
                  class="col-md-auto"
                  @click="
                    $router.push({
                      name: 'show-task',
                      params: { slug: item.slug || 'abcde', id: item.id },
                    })
                  "
                ></div>
                <b-dropdown class="transparent" right>
                  <template slot="button-content">
                    <i class="mdi mdi-dots-vertical"></i>
                  </template>
                  <b-dropdown-item
                    role="link"
                    :to="{
                      name: 'show-task',
                      params: {
                        slug: item.slug || 'abcde',
                        id: item.id,
                      },
                    }"
                  >
                    Consulter
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteItem(item.id)"
                    >Supprimer</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div
                @click="
                  $router.push({
                    name: 'show-task',
                    params: { slug: item.slug || 'abcde', id: item.id },
                  })
                "
                class="d-flex"
              >
                <h4 class="mr-auto text-truncate my-auto">
                  <router-link
                    class="task-title mr-auto my-auto text-truncate text-black"
                    style="text-decoration: none"
                    :to="{
                      name: 'show-task',
                      params: { slug: item.slug || 'abcde', id: item.id },
                    }"
                    >{{ item.libelle }}</router-link
                  >
                </h4>

                <span
                  v-b-tooltip.hover="
                    echeanceStatus(
                      item.createdAt,
                      item.deadline,
                      item.status
                    ).toUpperCase()
                  "
                  class="text-white text-center px-2 py-0 rounded"
                  :style="{
                    backgroundColor: dateBackgroundColor(
                      item.createdAt,
                      item.deadline,
                      item.status
                    ),
                  }"
                  >{{
                    moment(item.deadline, "DD-MM-YYYY").format("DD/MM/YYYY")
                  }}</span
                >
              </div>
              <span class="w-100">
                {{
                  item.phase.initiatives ? item.phase.initiatives.libelle : "-"
                }}
              </span>
              <div
                @click="
                  $router.push({
                    name: 'show-task',
                    params: { slug: item.slug || 'abcde', id: item.id },
                  })
                "
                class="image-grouped my-2"
              >
                <b-avatar
                  v-b-tooltip.hover="
                    item.responsable
                      ? item.responsable.firstname +
                        ' ' +
                        item.responsable.lastname
                      : '-'
                  "
                  style="
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                    height: 30px;
                    width: 30px;
                  "
                  class="mr-1"
                  :src="
                    item.responsable ? IMG_URL + item.responsable.avatarUrl : ''
                  "
                >
                  <div
                    v-if="!(item.responsable && item.responsable.avatarUrl)"
                    style="
                      width: 25px;
                      height: 25px;
                      border-radius: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    {{
                      item.responsable
                        ? item.responsable.firstname[0].toUpperCase() +
                          item.responsable.lastname[0].toUpperCase()
                        : "-"
                    }}
                  </div>
                </b-avatar>
              </div>
              <div
                @click="
                  $router.push({
                    name: 'show-task',
                    params: { slug: item.slug || 'abcde', id: item.id },
                  })
                "
                class="d-lg-flex justify-content-between align-items-center"
              >
                <div
                  style="position: relative; bottom: -10px"
                  class="ml-auto text-gray"
                >
                  {{
                    delayByDays(item.deadline) >= 0
                      ? `
                                    Délai de livraison ${delayByDays(
                                      item.deadline
                                    )} jour(s)
                                    `
                      : `${-delayByDays(item.deadline)} jour(s) de retard`
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            v-for="stage in statuses"
            :key="stage"
            :slot="`footer-${stage}`"
            @click="$router.push({ name: 'add-task' })"
          >
            <span href="">+ Ajouter un nouveau bloc</span>
          </div> -->
        </Kanban>
      </div>

      <div v-if="selected == 2" class="card p-3 mt-4">
        <div class="row m-0 p-0 table_responsivo">
          <div
            class="table col-3 h-100 col-sm-4 m-0 p-0"
            style="min-height: 400px"
          >
            <div class="header_sd_menu d-flex align-items-center">
              <th class="col-4 m-auto pl-2">Initiative</th>
              <th class="col-4 m-auto">Tâche</th>
              <th class="col-4 m-auto">Responsable</th>
            </div>

            <div v-if="loadingData">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </div>
            <div
              v-else-if="!filteredTasks.length"
              class="text-center text-gray h-30"
            >
              Table vide
            </div>
            <!-- {{ ganttData }} -->
            <div
              v-for="(item, index) in ganttData"
              :key="index + '1245'"
              class="sd_menu_row d-flex align-items-center"
              :style="{
                backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
                height: '38px !important',
              }"
            >
              <p class="p-0 col-4 my-auto text-truncate">
                <b-link
                  :to="{
                    name: 'show-initiative',
                    params: {
                      id: item.phase.initiatives.id,
                      slug: item.phase.initiatives.slug || 'abcde',
                    },
                  }"
                >
                  {{ item.phase.initiatives.libelle }}</b-link
                >
              </p>
              <p
                class="fl-2 col-4 mr-auto my-auto text-truncate"
                style="max-width: 150px !important"
              >
                <router-link
                  role="link"
                  :to="{
                    name: 'show-task',
                    params: {
                      slug: item.slug || 'abcde',
                      id: item.id,
                    },
                  }"
                  class="col-12 p-0"
                >
                  {{ item.libelle }}
                </router-link>
              </p>

              <p class="fl-2 text-center col-4 m-auto align-items-center">
                <b-avatar
                  v-b-tooltip.hover="
                    item.responsable
                      ? item.responsable.firstname +
                        ' ' +
                        item.responsable.lastname
                      : '-'
                  "
                  style="
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                    height: 30px;
                    width: 30px;
                  "
                  class="mr-1"
                  :src="
                    item.responsable ? IMG_URL + item.responsable.avatarUrl : ''
                  "
                >
                  <div
                    v-if="!(item.responsable && item.responsable.avatarUrl)"
                    style="
                      width: 25px;
                      height: 25px;
                      border-radius: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    {{
                      item.responsable
                        ? item.responsable.firstname[0].toUpperCase() +
                          item.responsable.lastname[0].toUpperCase()
                        : "-"
                    }}
                  </div>
                </b-avatar>
              </p>
            </div>
          </div>
          <div class="col-sm-8 col-9 m-0 p-0">
            <frappe-gantt
              v-if="filteredTasks.length"
              :view-mode="mode"
              :tasks="
                ganttData.map((item) => ({
                  id: `task-${item.id}`,
                  progress: item.avancement || 0,
                  name: `${item.avancement || 0}%`,
                  start: formatDate(item.startedAt),
                  end: formatDate(item.deadline),
                  taskId: item.id,
                }))
              "
              @task-date-updated="updateTasKDate"
              @task-progress-updated="updateTasKProgress"
              style="width: auto; height: 100%"
            />
          </div>
        </div>
        <b-pagination
          v-model="ganttCurrentPage"
          :total-rows="ALL_TASKS.length"
          :per-page="10"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import Kanban from "@/components/apps/Kanban";
import DropDownCustom from "@/components/custom/DropDown.vue";
import FrappeGantt from "@/components/custom/frappe-gantt.vue";
import TaskTable from "./components/TaskTable.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import moment from "moment";
import { IMG_URL } from "@/helpers/services/api";
import { orderBy, slice, uniqBy } from "lodash";
export default {
  name: "gallery",
  components: { Kanban, DropDownCustom, FrappeGantt, TaskTable },
  data() {
    return {
      ganttCurrentPage: 1,
      IMG_URL,
      loadingData: true,
      currentPage: 1,
      time1: null,
      isExpanded: [false, false, false, false, false],
      mode: "day",
      debugEventLog: [],
      ganttOptions: {
        maxRows: 100,
        maxHeight: 1000,
        title: {
          label: "Your project title as html (link or whatever...)",
          html: false,
        },
        row: {
          height: 100,
        },
        calendar: {
          hour: {
            display: false,
          },
        },
        chart: {
          progress: {
            bar: true,
          },
          expander: {
            display: true,
          },
        },
      },
      statuses: ["En attente", "En cours", "Suspendu", "Terminé"],

      selected: this.$store.state.views.activeView,
      tableItems: [],
      selectedRecords: [],
      filtered: false,
      showFilter: false,
      showTaskModal: false,
      showAddTaskModal: false,
      showEditTaskModal: false,
      selectedTask: {},
      filterMyTask: false,
      filterMyTeamTask: false,
      searchQuery: {
        libelle: "",
        initiative: "",
        status: "",
        echeance: "",
      },
      ganttView: [
        {
          text: "Jour",
          id: 1,
          color: " btn-inverse-primary",
        },
        {
          text: "Semaine",
          id: 2,
          color: "btn-inverse-danger ",
        },
        {
          text: "Mois",
          id: 3,
          color: "btn-inverse-info ",
        },
      ],
      selectedView: {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
    };
  },
  methods: {
    orderBy,
    slice,
    updateTasKDate: function (task, start, end) {
      this.tableItems = this.tableItems.map((item) => {
        if (item.id == task.taskId) {
          item.deadline = moment(end).format("DD/MM/YYYY");
          item.startedAt = moment(start).format("DD/MM/YYYY");
        }
        return item;
      });
      this.$store.dispatch("task/updateTask", {
        id: task.taskId,
        startedAt: moment(start).format("DD/MM/YYYY"),
        deadline: moment(end).format("DD/MM/YYYY"),
      });
    },

    updateTasKProgress: function (/*task, progress*/) {
      this.$store.dispatch("task/fetchAllData");
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },

    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    delayByDays(date) {
      return parseInt(
        moment
          .duration(
            moment(date, "DD-MM-YYYY").diff(moment(new Date(), "DD-MM-YYYY"))
          )
          .asDays()
      );
    },
    myTasks() {
      if (this.isManager || this.isAdmin || this.isSuper) {
        this.filterMyTeamTask = false;
        this.filterMyTask = !this.filterMyTask;
        this.tableItems = this.filterMyTask
          ? this.ALL_TASKS.filter(
              (task) =>
                parseInt(task.responsable.id) ===
                parseInt(this.AuthenticatedUser.id)
            ).map((task) => ({
              ...task,
              echeance: this.echeanceStatus(
                task.createdAt,
                task.deadline,
                task.status
              ),
            }))
          : this.ALL_TASKS.map((task) => ({
              ...task,
              echeance: this.echeanceStatus(
                task.createdAt,
                task.deadline,
                task.status
              ),
            }));
      }
    },
    myTeamTasks() {
      this.filterMyTask = false;
      this.filterMyTeamTask = !this.filterMyTeamTask;
      // this.tableItems = this.TASKS;
      this.tableItems = this.ALL_TASKS.map((task) => ({
        ...task,
        echeance: this.echeanceStatus(
          task.createdAt,
          task.deadline,
          task.status
        ),
      }));
      this.tableItems = !this.filterMyTeamTask
        ? this.tableItems
        : this.TEAM_TASKS.map((task) => ({
            ...task,
            echeance: this.echeanceStatus(
              task.createdAt,
              task.deadline,
              task.status
            ),
          }));
    },
    formatDate(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    showItem(item) {
      this.selectedTask = item;
      this.showTaskModal = !this.showTaskModal;
    },
    editItem(item) {
      this.selectedTask = item;
      this.showEditTaskModal = !this.showEditTaskModal;
    },

    onDragged: function (e, f) {
      this.$store
        .dispatch("task/updateTask", {
          id: e,
          status: f,
        })
        .then(() => {
          this.$store.dispatch("task/fetchAllData");
          // this.tableItems = this.tableItems.map((item) => {
          //   if (item.id == e) item.status = f;
          //   return item;
          // });
        });
    },
    onHover: function (type, index) {
      let tmpTasLis = [...this.newTasks];

      if (type == 0) {
        tmpTasLis[index].style = "";
      } else {
        tmpTasLis[index].style = "text-truncate";
      }
    },
    changeView: function (val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView = this.ganttView[val];
    },
    onValueChange: function (e) {
      this.selected = e;
      this.$store.dispatch("views/changeView", e);

      this.$store.dispatch("views/changeView", e);
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.tableItems = this.tableItems.filter((item) => item.id !== id);
          if (this.$store.dispatch("task/deleteTask", id))
            Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
  mounted() {
    // if (this.isAdmin || this.isSuper) {
    //   this.filterMyTask = false;
    // }
  },

  computed: {
    ...mapGetters("task", ["ALL_TASKS", "TEAM_TASKS"]),
    ...mapGetters("cStatus", ["STATUS_TASKS"]),
    ...mapGetters([
      "isManager",
      "isArchitect",
      "isPo",
      "isAdmin",
      "isSuper",
      "isCollaborator",
      "isFinance",
      "AuthenticatedUser",
    ]),

    ganttData() {
      return slice(
        orderBy(this.filteredTasks, "phase.initiatives.libelle"),
        (this.ganttCurrentPage - 1) * 10,
        this.ganttCurrentPage * 10 - 1
      );
    },
    responsableTitle: function ({ index, value }) {
      if (this.isExpanded[index]) {
        return (
          value.split(" ")[0].substring(0, 1) +
          ". " +
          value.split(" ")[1].substring(0, 1)
        );
      } else {
        return value;
      }
    },

    filteredTasks: function () {
      return uniqBy(
        this.tableItems
          .filter(
            (task) =>
              task.libelle
                .toLowerCase()
                .indexOf(this.searchQuery.libelle.toLowerCase()) > -1 &&
              (task.phase && task.phase.initiatives
                ? task.phase.initiatives.libelle
                    .toLowerCase()
                    .indexOf(this.searchQuery.initiative.toLowerCase()) > -1
                : true) &&
              (this.searchQuery.echeance
                ? task.echeance
                    .toLowerCase()
                    .indexOf(this.searchQuery.echeance.toLowerCase()) > -1
                : true) &&
              (this.searchQuery.status
                ? task.status
                    .toLowerCase()
                    .indexOf(this.searchQuery.status.toLowerCase()) > -1
                : true)
          )
          .filter((task) =>
            this.filterMyTask
              ? parseInt(task.responsable.id) ===
                parseInt(this.AuthenticatedUser.id)
              : true
          ),
        "id"
      );
    },
  },
  created() {
    this.$store.dispatch("task/fetchAllData").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("task/fetchTeamTasks");

    this.$store.dispatch("cStatus/fetchAllStatusByType", "Task");
  },
  updated: function () {
    let progresses = document.querySelectorAll(".bar-progress");
    if (progresses.length > 0) {
      progresses.forEach((it) => {
        var avancement = parseInt(
          it.parentElement.lastElementChild.innerHTML.split("%")[0]
        );
        var color =
          avancement > 20 && avancement <= 70
            ? "#f5f245"
            : avancement > 70
            ? "#23d92c"
            : "#f5ba45";

        it.style.fill = color;
      });
    }
  },

  watch: {
    ALL_TASKS: function () {
      this.tableItems = this.ALL_TASKS.map((task) => ({
        ...task,
        avancement: task.avancement.toFixed(2),
        echeance: this.echeanceStatus(
          task.createdAt,
          task.deadline,
          task.status
        ),
      }));
    },
  },
};
</script>

<style scoped>
.add {
  width: 100%;
}

@media (min-width: 600px) {
  .add {
    width: max-content;
  }
}

.top_gantt_controls {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 10px;
}

.header_sd_menu {
  height: 60px;
  background: #37d3bc !important;
  width: 100%;
  color: white;
  border: 0;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.table_responsivo {
  overflow: scroll;
}

.cc-red g rect {
  fill: red !important;
}
</style>
<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}

.board-wrapper .portlet-card-list {
  padding-left: 0;
  list-style: none;
  min-height: 70px;
  margin-bottom: 16px;
  overflow: hidden;
  max-height: 100vh;
  overflow-y: auto;
}

.drag-container {
  overflow-x: auto;
}

.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}

#gantt-table tbody tr:nth-child(odd) {
  background: #ffffff;
}

#gantt-table tbody tr:nth-child(even) {
  background: #f5f5f5;
}

.gantt .grid-header {
  fill: #37d3bc !important;
  stroke: #e0e0e0;
  stroke-width: 1.4;
}

#gantt-table thead {
  background: #37d3bc !important;
}

.gantt .lower-text {
  fill: white;
}

.gantt .upper-text {
  fill: white;
}

.gantt .grid-row {
  fill: #ffffff;
}

.gantt .grid-header {
  fill: #37d3bc !important;
  stroke: #e0e0e0;
  stroke-width: 1.4;
}

#gantt-table thead {
  background: #37d3bc !important;
}

.gantt .lower-text {
  fill: white;
}

.gantt .upper-text {
  fill: white;
}

.gantt .grid-row {
  fill: #ffffff;
}
</style>
