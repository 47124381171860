<template>
  <b-progress
    max="100"
    show-progress
    height="15px"
    style="background-color: #cacccd !important"
    
  >
    <b-progress-bar
      :value="progress"
      :label="`${progress}%`"
      :style="`background-color: ${
        progress > 20 && progress <= 70
          ? '#f5f245'
          : progress > 70
          ? '#23d92c'
          : '#f5ba45'
      }`"
    >
      <small class="justify-content-center d-flex position-absolute w-100"
        >60% complete</small
      >
    </b-progress-bar>
  </b-progress>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>
