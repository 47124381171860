<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              striped
              bordered
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(status)="data">
                <label>{{ data.value }}</label>
              </template>
              <template v-slot:cell(libelle)="data">
                <b-link
                  :to="{
                    name: 'show-task',
                    params: {
                      slug: data.item.slug,
                      id: data.item.id,
                    },
                  }"
                  style="max-width: 100px !important"
                  class="text-truncate"
                >
                  {{ data.value }}
                </b-link>
              </template>
              <template v-slot:cell(initiative)="data">
                <b-link
                  v-if="data.item.phase && data.item.phase.initiatives"
                  :to="{
                    name: 'show-initiative',
                    params: {
                      slug: data.item.phase.initiatives.slug || 'abcde',
                      id: data.item.phase.initiatives.id,
                    },
                  }"
                  >{{
                    data.item.phase && data.item.phase.initiatives
                      ? data.item.phase.initiatives.libelle
                      : "-"
                  }}</b-link
                >
              </template>
              <template v-slot:cell(action)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(avancement)="data">
                <TaskProgress :progress="parseFloat(data.value)" />
              </template>
              <template v-slot:cell(deadline)="data">
                <span
                  v-b-tooltip.hover="
                    echeanceStatus(
                      data.item.createdAt,
                      data.value,
                      data.item.status
                    ).toUpperCase()
                  "
                  class="text-white text-center px-2 py-1 rounded"
                  :style="{
                    backgroundColor: dateBackgroundColor(
                      data.item.createdAt,
                      data.value,
                      data.item.status
                    ),
                  }"
                  >{{ data.value }}</span
                >
              </template>
              <template v-slot:cell(task)="data">
                <a :href="data.value.link">{{ data.value.title }}</a>
              </template>
              <template v-slot:cell(responsable)="data">
                <TableResponsable
                  :responsable="data.value ? data.value : null"
                />
              </template>
              <template #cell(actions)="data">
                <table-actions
                  class="justify-content-end"
                  :actions="['delete']"
                  @deleteItem="$emit('deleteItem', data.item.id)"
                ></table-actions>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="10"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import moment from "moment";
import TableActions from "@/components/custom/TableActions";
import TaskProgress from "./TaskProgress";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableResponsable, TableActions, TaskProgress },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "libelle", label: "Tâche", sortable: true },
        { key: "type", label: "Type Tâche", sortable: true },
        { key: "initiative", label: "Initiative", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "avancement", label: "Avancement", sortable: true },
        { key: "deadline", label: "Fin Prévisionnelle", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },

    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
  },
  props: {
    items: Array,
    loading: Boolean,
  },
  watch: {
    currentPage: function () {
      this.$emit("changePage", this.currentPage);
    },
  },
};
</script>
