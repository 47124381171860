var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-progress',{staticStyle:{"background-color":"#cacccd !important"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background-color: ${
      _vm.progress > 20 && _vm.progress <= 70
        ? '#f5f245'
        : _vm.progress > 70
        ? '#23d92c'
        : '#f5ba45'
    }`),attrs:{"value":_vm.progress,"label":`${_vm.progress}%`}},[_c('small',{staticClass:"justify-content-center d-flex position-absolute w-100"},[_vm._v("60% complete")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }